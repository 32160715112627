@import "../_style/main.css";
@import "../_style/normalize.css";

$blackColor: #020304;
$bgColor: rgba(48,48,48,1);

$mobile: "(min-width: 100px) and (max-width: 1249px)";
$desktop: "(min-width: 1250px)";


@media #{$mobile} {
  nav {
    display: block;
  }
  .logo,
  .header,
  .banner, {
    display: none;
  }
}

@media #{$desktop} {
  nav {
    display: none;
  }
  label[for=burger] {
    display: none;
  }
  label[for=burger-a] {
    display: none;
  }
}

input#burger {
  visibility: hidden;
  position: fixed;
}

#burger span {
      text-transform: none;
      opacity: 1;
      font-weight: 300;
      font-size: 12px;
    }
  
    input + label[for=burger] {
      position: -webkit-sticky;
      position: fixed;
      top: 8vh;
      left: 40px;
      height: 20px;
      width: 1.5em; 
      z-index: 5;
      margin-top: -20px;
      span {
        position: absolute;
        width: 100%;
        height: 4px;
        top: 0.5rem;
        margin-top: -1px;
        right: 0;
        display: block;
        background: rgba(162,110,97,1);
        transition: .5s;
      }
      span:first-child {
        top: 0px; 
      }
      span:last-child {
        top: 1rem; 
      }      
    }
    label[for=burger]:hover {
      cursor: pointer;
    }
    input:checked + label[for=burger] { 
      span {
        opacity: 0;
        top: 50%;
      }
      span:first-child {
        opacity: 1;
        transform: rotate(405deg);
      }
      span:last-child {
        opacity: 1;
        transform: rotate(-405deg);
      }
    }
    input ~ nav {
      background: #303030;
      position: -webkit-sticky;
      position: fixed;
      top: -19px;
      right: 0;
      width: 100%;
      height: 16vh;
      z-index: 3;
      transition: .5s;
      transition-delay: .5s;
      overflow: hidden;
      border-bottom: solid 3px rgba(162,110,97,1);
      > ul {
        text-align: center;
        position: absolute;
        top: 10vh;
        right: 20%;
        left: 20%;
        display: grid;
        padding-left: 0;
        > li {
          opacity: 0;
          transition: .5s;
          transition-delay: 0s;
          padding-left: 0;
          margin-right: 0;
          > a {
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 700;
            font-family: 'Quicksand', sans-serif;
            font-size: 1.5em;
            display: none;
          }
        }
      }
    }
  input:checked + label[for=burger] ~ nav { 
      height: 50vh;
      transition-delay: 0s;
      > ul {
        > li {
          opacity: 1;
          transition-delay: .5s;
        }
      }
    } 

input:checked + label[for=burger] ~ nav > ul > li > a {
  display: grid;
  transition-delay: 1s;
}

input:checked + label[for=burger] ~ nav > .mobile-logo {
  display: none;
  transition-delay: .5s;
}

/*
TESTING MOBILE NAV CLOSING
*/

/*
#burger-a span {
      text-transform: none;
      opacity: 1;
      font-weight: 300;
      font-size: 12px;
    }
*/
  
    input + label[for=burger-a] {
      position: -webkit-sticky;
      position: sticky;
      width: 1.5em; 
      z-index: 5;
/*      span {
        position: absolute;
        width: 100%;
        height: 4px;
        top: 0.5rem;
        margin-top: -1px;
        right: 0;
        display: block;
        background: rgba(162,110,97,1);
        transition: .5s;
      }

      span:first-child {
        top: 0px; 
      }
      span:last-child {
        top: 1rem; 
      }      
      */
    }
    label[for=burger-a]:hover {
      cursor: pointer;
    }
    input:checked + label[for=burger-a] { 
      #burger span {
        opacity: 0;
        top: 50%;
      }
      #burger span:first-child {
        opacity: 1;
        transform: rotate(405deg);
      }
      #burger span:last-child {
        opacity: 1;
        transform: rotate(-405deg);
      }
    }
    
  input:checked + label[for=burger-a] {
    & #burger span {
      height: 15vh;
      transition-delay: 0s;
      display: none;
      }
    } 

input:checked + label[for=burger-a] ~ #burger > nav > ul > li > a {
  display: grid;
  transition-delay: 1s;
}

input:checked + label[for=burger-a] ~ #burger > nav > .mobile-logo {
  display: none;
  transition-delay: .5s;
}


